import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Host from '../views/Host'
import Guests from '../views/Guests'
import Podcast from '../views/Podcast'
import Inspiration from '../views/Inspiration'
import Wisdom from '../views/Wisdom'
import Exclusive from '../views/Exclusive'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/host',
    name: 'host',
    component: Host
  },

  {
    path: '/guests',
    name: 'guests',
    component: Guests
  },

  {
    path: '/podcast',
    name: 'podcast',
    component: Podcast
  },

  {
    path: '/wisdom',
    name: 'Wisdom',
    component: Wisdom
  },

  {
    path: '/inspiration',
    name: 'inspiration',
    component: Inspiration
  },


  {
    path: '/exclusive',
    name: 'Exclusive',
    component: Exclusive
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
