<template>
  <v-app>
    <v-app-bar app color="#FFFFFF" dark>
      <v-img
        class="mx-2"
        max-height="40"
        max-width="40"
        src="../src/assets/Clues of Success Logo.jpeg"
      ></v-img>

      <v-spacer></v-spacer>

      <v-btn to="/host" color="#1A237E" text>
        <span>Host</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn to="/guests" color="#1A237E" text>
        <span>Guests</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        color="#D50000"
        text
        to="/exclusive"
      >
        <span>Exclusive</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn to="/about" color="#1A237E" text>
        <span>About</span>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn to="/podcast" color="#1A237E" text>
        <span>Podcast</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer>2023 — Official Clues of Success </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
